import React from 'react';
const slugify = (name) =>
  name &&
    name
      .toLowerCase()
      .replaceAll(/\(|\)|\s\/|,|’|'/g, '')
      .replaceAll('/', ' ')
      .replaceAll(/\s/g, '-')
      .replaceAll('&', 'and') ||
  null;

const getPostBlocksFiltered = (blocks, sideArr) => {
  // runs shallow check looking for blocks with special identifiers (we can use data.ids on the wrappers)
  const blocksObj = {
    body: [],
    side: [],
  };
  for (const block of blocks) {
    const layout = block?.props?.attributes?.layoutType;
    if (sideArr.includes(layout) && block?.props?.name === 'cs-inner-cta') {
      blocksObj.side.push(block);
    } else {
      blocksObj.body.push(block);
    }
  }

  return blocksObj;
};

const CsTierOneMain = (props) => {
  const { attributes, children } = props;
  const {
    paddingBottom, paddingTop, sectionName,
  } = attributes;
  console.log(paddingBottom, paddingTop);
  const postBlocks = getPostBlocksFiltered(children, ['narrow']);

  return (
    <div
      className={`cs-tier-one-main-section customerSpotlight__container 
        ${paddingBottom === 'no-padding-bottom' ? 'pb-0' : 'md:pb-120 sm:pb-96 pb-64'} 
        ${paddingTop === 'no-padding-top' ? 'pt-0' : 'md:pt-120 sm:pt-96 pt-64'} 
        flex flex-col md:flex-row justify-between wrapper gap-x-24`
      }
      id={slugify(sectionName)}
    >
      <div className={`cs-main-sidebar relative md:max-w-300 ${sectionName ? 'mb-40 sm:48 md:mb-0' : ''} flex flex-col justify-between`}>
        <div className="h-100%">
          <div className="flex items-center flex md:sticky md:top-120">
            {sectionName ? <h3 className="text-plum-800 mb-0">{sectionName}</h3> : null }
          </div>
        </div>
        {postBlocks.side ? (
          <div className="w-100% hidden md:block md:mt-48 relative">
            {postBlocks.side}
          </div>
        )

          : null}
      </div>
      <div className="cs-tier-one-main-content customerSpotlight__content flex-1 max-w-[735px] w-100%">
        {postBlocks.body}
      </div>
    </div>
  );
};

export default CsTierOneMain;
