// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/manual-setup/#create-initialization-config-files

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"3d26fcf5b42fedb20510e7043409e57fa89e1d26"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

import sentryBaseConfig from './sentry.base.config';

if (process.env.NEXT_PUBLIC_ENABLE_SENTRY === 'true') {
  Sentry.init(sentryBaseConfig);
}
